@import "variables";
@import "node_modules/bootstrap/scss/bootstrap";

@font-face {
	font-family: 'MetaPro-Medi';
	src: url('/assets/fonts/MetaPro-Medi.eot');
	src: local('☺'), url('/assets/fonts/MetaPro-Medi.woff') format('woff'), url('/assets/fonts/MetaPro-Medi.ttf') format('truetype'), url('/assets/fonts/MetaPro-Medi.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
@font-face {
	font-family: 'MetaPro-Norm';
	src: url('/assets/fonts/MetaPro-Norm.eot');
	src: local('☺'), url('/assets/fonts/MetaPro-Norm.woff') format('woff'), url('/assets/fonts/MetaPro-Norm.ttf') format('truetype'), url('/assets/fonts/MetaPro-Norm.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

/** Generated by FG **/
@font-face {
	font-family: 'MetaPro-Light';
	src: url('/assets/fonts/MetaPro-Light.eot');
	src: local('☺'), url('/assets/fonts/MetaPro-Light.woff') format('woff'), url('/assets/fonts/MetaPro-Light.ttf') format('truetype'), url('/assets/fonts/MetaPro-Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}





body{
    background-position: top center;
    background-size: cover;
    -webkit-font-smoothing: antialiased;
}

.tmp-default{
    .bg-wrapper{
        background-attachment: fixed;
    }
}
.tmp-home{
    background-image: url(/assets/page-bg.jpg);
    
    #site-header .navbar{background: transparent;}
}
.tmp-akademi{
    .contents{
        padding:1rem;
    }
}

a{
    transition: 0.2s;
}

.sectionTitle{
    font-size:24px;
    text-transform: uppercase;
    font-family:'MetaPro-Medi';
    
    &.withDivider{
        background-color: $body-bg;
        padding:0 1rem 0 0;
        display: inline-block;
    }
}
.tau-border{
    border-top-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}
.tau-border-r{
    border-top-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
}

.tau-border-lg{
    border-top-left-radius: $border-radius-lg;
    border-bottom-right-radius: $border-radius-lg;
}
.tau-border-r-lg{
    border-top-right-radius: $border-radius-lg;
    border-bottom-left-radius: $border-radius-lg;
}

.tau-border-xl{
    border-top-left-radius: $border-radius-lg*2;
    border-bottom-right-radius: $border-radius-lg*2;
}
.tau-border-r-xl{
    border-top-right-radius: $border-radius-lg*2;
    border-bottom-left-radius: $border-radius-lg*2;
}


.abs-full{
    position: absolute;
    left:0;
    right:0;
    top:0;
    bottom: 0;
}

.gradient-dark{
    background: -moz-linear-gradient(left, rgba(0,0,0,0)  25%,  $dark 95%);
    background: -webkit-linear-gradient(left, rgba(0,0,0,0) 25%,$dark 95%);
    background: linear-gradient(to right, rgba(0,0,0,0) 25%,$dark 95%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='$dark',GradientType=1 );
}
.gradient-primary{
    background: -moz-linear-gradient(left, rgba(0,0,0,0)  25%,  $primary 95%);
    background: -webkit-linear-gradient(left, rgba(0,0,0,0) 25%,$primary 95%);
    background: linear-gradient(to right, rgba(0,0,0,0) 25%,$primary 95%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='$primary',GradientType=1 );
}
.gradient-secondary-light{
    background: -moz-linear-gradient(left, rgba(0,0,0,0)  25%,  $yesil-light 95%);
    background: -webkit-linear-gradient(left, rgba(0,0,0,0) 25%,$yesil-light 95%);
    background: linear-gradient(to right, rgba(0,0,0,0) 25%,$yesil-light 95%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='$yesil-light',GradientType=1 );
}

.gradient-v-primary-bg{
    background: -moz-linear-gradient(top, rgba(25, 157, 178, 0.4) 0%, rgba(247,247,247,1) 100%);
    background: -webkit-linear-gradient(top, rgba(25, 157, 178, 0.4) 0%,rgba(247,247,247,1) 100%);
    background: linear-gradient(to bottom, rgba(25, 157, 178, 0.4) 0%,rgba(247,247,247,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#199db2', endColorstr='#f7f7f7',GradientType=0 );
}

.gradient-v-trans-bg{
    background: -moz-linear-gradient(top, rgba(52,55,65, 0.6) 0%, rgba(247,247,247,1) 100%);
    background: -webkit-linear-gradient(top, rgba(52,55,65, 0.6) 0%,rgba(247,247,247,1) 100%);
    background: linear-gradient(to bottom, rgba(52,55,65, 0.6) 0%,rgba(247,247,247,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#f7f7f7',GradientType=0 );
}

.gradient-v-trans-white{
    background: -moz-linear-gradient(top, rgba(0,0,0, 0.0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0, 0.0) 0%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0, 0.0) 0%,rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
}


.withDivider{
    &:before {
        content: ' ';
        position: absolute;
        width: 100%;
        border-top: 1px solid #5b6072;
        opacity: 0.2;
        top: 15px;
        z-index: -1;
    }
}

.header{
    position: relative; overflow: hidden;
    a{background-color: $body-bg; display: inline-block; padding:0 0rem 0 1.5rem; float: right; margin-top: 2px; font-weight: bold;} 
}

.image{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: relative;
}

.withShadow{
    box-shadow: 0px 8px 13px 0 rgba(0, 0, 0, 0.25);
}

.container.oversize{
    max-width: 1170px;
    padding: 0px 30px;
}


#site-header{
    .navbar{
        padding: 0 1rem;
        background: #fff;
    }
    .collapse{
        position: relative;
    }
    
    .navbar-nav{
        text-transform: uppercase;
        font-family:'MetaPro-Medi';
    }
    .nav-link{
        text-align: center;
        padding-top: 0;
        margin-top: 50px;
        
        i{color:#bfc0c3;}
        &:hover i{color:#343741;}
    }

    .dropdown-menu{
        margin: 0;
        text-transform: none;
        font-size:15px;;
    }
    .dropdown-toggle::after{
        display:none;
    }
    .dropdown-item{
        padding: 0.25rem 1rem;
    }

    .topmenu{
        background-color: #f7f7f7;
        border-bottom: 1px solid #e4e4e4;
        position: absolute;
        top: 0;
        right: 0;
        padding-left: 100px;
        font-size:15px;
        
        a{text-decoration: none; padding:6px 10px;}
        
        li{
            display:inline-block;
        }
        
        
        &:after{
            content: ' ';
            position: absolute;
            width: 100px;
            background: -moz-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
            background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
            height: 36px;
            top:1px;
            left:0;
        }
        
        
        i{
            color: #089baf;
            font-size: 16px;
            vertical-align: middle;
            margin-top: -3px;
        }
        
        .btn{border:none; i{font-size:21px; color: rgba(52, 55, 65, 0.5);}} 
        .btn:hover i{color: rgba(52, 55, 65, 1);} 
        
    }
}

#home-slider{
    .carousel-item{
        // height: 440px;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .abs-full{
        background: -moz-linear-gradient(top, rgba(0,61,89,0.8) 10%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(top, rgba(0,61,89,0.8) 10%,rgba(255,255,255,0) 100%);
        background: linear-gradient(to bottom, rgba(0,61,89,0.8) 10%,rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc003d59', endColorstr='#00ffffff',GradientType=0 );
        z-index: 100;
    }
    
    .content{
        color:#fff;
        z-index: 150;
        
    }
    h2{font-size:36px}
    p{font-size:16px;  max-width: 510px}
    
}




#adayogrenciSlider{
    .tagLine{
        margin: 0;
        span{
            font-size:26px;
            float: left;
            margin-right: 15px;
            border:2px solid #82b43f;
            border-radius: 20px;
            color:#fff;
            text-align: center;
            height: 40px;
            width: 40px;
            position: relative;
            top: 5px;
            line-height: 26px;
        }
    }
    .controls{
        background-color: #fff;
        border: 1px solid #e1e1e1;
        text-align: center;
        width: 30px;
        border-radius:36px;
        font-size:14px;
        
        
        a{display: block; color:#b5b6ba !important; padding:4px 0;}
        a:hover{color:#343741 !important;}
        // a+a{margin-top: 5px;}
    }
}
#home-announcements{
    
    .date{
        color:#fff;
        text-align: center;
        width: 60px;
        margin:20px auto 0 auto;
        font-family:'MetaPro-Light';
        background-color: #fff;
        border: solid 2px #e1e1e1;
        position: relative;
        border-top-left-radius: $border-radius-lg;
        border-bottom-right-radius: $border-radius-lg;
        
        span{
            display: block;
            position: relative;
            top: -13px;
            left: 13px;
        }
        
        .day{
            font-size:34px;
            border-top-left-radius: $border-radius-lg;
            // padding: 5px 0;
        }
        .month{
            border-bottom-right-radius: $border-radius-lg;
            padding:0 0 3px 0;
        }
    }
    p:last-child{margin-bottom: 0;}
    small{display: block;}
    .time{margin-top: 5px;}
    .material-icons{ font-size:16px; top: 4px; position: relative;} 
}
#home-news{
    #haberlerSlider{
        .oversize{padding-top: 2rem; padding-bottom: 2rem;}

        .carousel-indicators{bottom: 5px;}
        .carousel-indicators li{
            background-color: rgba(0,0,0,0.5);
        }
        .carousel-indicators li.active{
            background-color: rgba(0,0,0,0.7);
        }
    }
    article{
        
        .image{
            height: 115px;
            margin-bottom: 1rem;;
        }
        
        .date{
            position: absolute;
            bottom:10px;
            font-size:14px;
            color:#fff;
            left: 10px;
            padding: 5px 10px;
            font-weight: 300;
            text-transform: uppercase;
        }
        .content{
            padding: 0 1rem;
        }
        .btn{margin-bottom: 1em; margin-top: 1em; font-size: 14px; margin-left: 1em;}
        h5{font-size:16px; font-family:'MetaPro-Medi';}
        p{font-size:15px}
    }
}
#tau-programs{
    .program{
        background-repeat: no-repeat;
        background-size: cover;
        margin-left: 30px;
        margin-right: 25px;
        padding:2.5rem 0 ;
        color:#fff;
        margin-bottom: 2rem;
        font-size: 17px;
        
        h4{padding: 1rem 1.5rem 1rem 2rem; margin-left: -2.5rem; display: inline-block;}
        a{
            color:#fff; text-decoration: none;
            
            span{color:rgba(255,255,255,0.5); margin-right: 4px;}
            
            &:hover{
                span{color:rgba(255,255,255,0.8);}
                
            }
        }
        ul{margin-bottom: 0; font-size:17px;}
        &:last-child{margin-bottom: 0;}
    }
    
    .license{
        background-image:url(/assets/lisans-programlari.jpg);
    }
    .license-up{
        background-image:url(/assets/lisansustu.jpg);
    }
    .candidate{
        h4{background-color: #656974;}
        p{font-size:20px}
        background-image:url(/assets/aday-ogrenciler.jpg);
    }
    
}

#site-footer{
    font-size:14px;
    
    p{
        line-height: 20px;
    }
    p:last-child{margin-bottom: 0;}
    
    ul li{
        font-size:16px;
    }

    .material-icons{
        background: #fff;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        text-align: center;
        font-size: 22px;
        line-height: 40px;
        float: left;
        margin-right: 10px;
        color: #089baf;
    }
    .text-primary{
        color:#089baf;
    }
    .bottom{
        background-color: #343741;
    }
    
    .social-media{
        font-size:24px;
        .fa{margin-right: 10px;}
    }
}

.template-content{
    position: relative;
    overflow: hidden;
}

.bg-wrapper{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    
}
.overlay-mesh{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

#page-all{
    position: relative;
    z-index: 11;
}



#intro{
    color:#fff;
    
    a{
        color:#fff;
        text-decoration: none;
    }
    
    hr{
        width: 100px;
        border-color: rgba(255,255,255,0.5);
        margin: 0 0 0.5rem 1rem;
    }
    
    &.color-1{
        
        background: -moz-linear-gradient(right, rgba(0,0,0,0)  0%,   #007f49 75%);
        background: -webkit-linear-gradient(right, rgba(0,0,0,0) 0%, #007f49 75%);
        background: linear-gradient(to left, rgba(0,0,0,0) 0%, #007f49 75%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007f49', endColorstr='#00000000',GradientType=1 );
    }
}

#subMenu{
    color:rgba(255,255,255,0.7);
    a{color:inherit; text-decoration: none;}
    a:hover,a.active,li.active a{color:#fff;}
    
    li + li a::before{
        display: inline-block;
        padding-right: 0.5rem;
        color: rgba(255, 255, 255, 0.2);
        content: "|";
    }
    
    &.color-1{
        background-color: #007f49;
    }
}

.pageTitle{
    padding-left: 1rem;
    font-size:48px;
}

.breadcrumb{
    font-size:24px;
    margin-bottom: 0;
    
} 

#page-content{
    .image{
        height: 300px;;
    }
    .imageCaption{
        bottom: 2rem;
        position: absolute;
        padding: 0 3rem;
        max-width: 90%;
        
        &.quote{
            bottom:-30px;
        }
    }
}


#leftNavigation{
    .card{border:none}
    a{color:$gray-800;text-decoration: none}
    a:hover{color:#000;}
    
    .card-header{
        h5{
            font-size:18px;
            text-transform: uppercase;
            font-weight: bold;
            
            a{padding: 0.75rem 1.5rem;}
            a[aria-expanded=true]{
                background-color: #fafafa;
                i.down{display:inline-block}
                i.right{display:none}
            }
        }
        
        i.material-icons{
            float: right;
        }
        i.down{display::none}
        
        padding: 0;
        border-bottom: 1px solid #e1e1e3;
        background: none;
    }
    
    .subpages{
        margin-bottom: 0;
        a{display: block;margin-bottom: 3px;}
        
        li a:before{content:' -'; padding-right: 5px;}
        
        a.active,a:hover{color:$primary}
    }
    
    .card-body{
        background-color: #eff1f7;
        padding: 1.25rem 1.5rem;
        font-size:15px;
        
        
    }
}

#academy-welcome{
    .semiBorderBottom{
        font-size:20px;
        text-transform: uppercase;
        font-weight: normal;
        margin-top: 2.0rem;
    }
    .buttons{
        .btn{font-size:15px; border-width:2px}
        .btn-outline-light{color:#fff}
    }
    
    .overflow-image{
        background-color: rgba(0,0,0,0.2);
        img{
            position: relative;
            top: -15px;
            left: 15px;
        }     
    }
    
    article{
        .date{
            color:#fff;
            text-align: center;
            width: 60px;
            margin:0px auto 0 auto;
            font-family:'MetaPro-Light';
            border: solid 2px rgba(255,255,255,0.5);
            position: relative;
            border-top-left-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;
            
            span{
                display: block;
                position: relative;
            }
            .dateBorder{
                width: 30px;
                transform: rotate(-45deg);
                height: 1px;
                border-bottom: solid 1px rgba(255,255,255,0.5);
                position: absolute;
                top:35px;
                left:15px;
            }
            
            .day{
                font-size:24px;
                border-top-left-radius: $border-radius-lg;
                text-indent: -15px;
            }
            .month{
                border-bottom-right-radius: $border-radius-lg;
                text-indent: 25px;
                padding-bottom: 5px;
            }
        }
        p{font-size:14px; margin-bottom: 0;}
    }
    
    &.color-1{
        color: #fff;
        
        .container:nth-child(1){
            background: url(/assets/bg-academy-welcome.png) top left no-repeat #00663a;
            padding-bottom: 2rem;
            border-top-left-radius: 1rem;
            
        }
        .container:nth-child(2){
            background-color: #00522e;
            border-bottom-right-radius: 1rem;
        }
        
        .btn-outline-light{
            border-color:#43ae81;
            &:hover{background-color:#00522e}
        }
        
        .date{
            border-color: #43ae81;
            
            .dateBorder{
                border-bottom-color:#43ae81;
            }
        }
        
        
    }
    
}


.quote:before { content: '\201C'; font-size:50px; position: absolute; margin-left: -30px; margin-top: -15px; color:$primary;}
.quote:after { content: '\201D'; font-size:50px; position: absolute; margin-top: 10px; margin-left: 15px; color:$primary }

.color-1 {
    .quote:before, .quote:after{color:#007f49}
    //     .semiBorderBottom{
        //         &:after{border-bottom-color:  #007f49; opacity: 0.2;}
        //     }
    }
    
    .semiBorderBottom{
        &:after{
            content: ' ';
            border-bottom: 2px solid $gray-300;
            width: 80px;
            display: block;
            margin: 10px 0;
        }
        &.light-border:after{
            border-bottom: 2px solid rgba(255,255,255,0.4);
        }
        &.border-center:after{
            margin: 10px auto 0 auto;
        }
    }
    
    #contact-details{
        padding: 0;
        border-bottom-right-radius: $border-radius-lg*2;
        
        .social-media{
            font-size:24px;
            a{text-decoration: none;}
            .fa{margin-right: 10px;}
        }
        
        .top{
            color:#fff;
            background-color:rgba(52,55,65,0.8);
            border-top-left-radius: $border-radius-lg*2;
            
            .material-icons{
                background: #089baf;
                width: 80px;
                height: 80px;
                border-radius: 80px;
                font-size: 32px;
                line-height: 80px;
                color: #fff;
                display: block;
                margin:0px auto 15px auto;
            }
            p{float: left; width: 50%; font-size:15px; text-align: center; margin-bottom: 0;}
            a{color:inherit}
        }
    }
    
    .form-control,textarea{
        background-color: #f6f6f7;
        color: #343741;
        border:none;
        font-size:15px;
    }
    
    .btn-outline-secondary-light:hover{color:#fff}
    
    #carouselHomeSliderControls {
        .carousel-control-prev, .carousel-control-next{
            width: 5%;
        }
    }
    #carouselFacultySliderControls {
        .carousel-control-prev, .carousel-control-next{
            width: 2%;
        }
    }
    
    
    @import "responsive"