

$turkuaz: #199db2; 
$turkuaz-dark: #147e8e;
$yesil-light: #83b43d;
$yesil: #699031;



$en-acik-gri: #ebebec;
$acik-gri: #656974;
$koyu-gri: #343741;

$primary: $turkuaz;
$secondary: $yesil;

$theme-colors: (
   "primary-dark": $turkuaz-dark,
   "secondary-light": $yesil-light
);

$gray-100: #f8f9fa;
$gray-200: $en-acik-gri;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: $acik-gri;
$gray-700: #495057;
$gray-800: $koyu-gri;
$gray-900: #212529;

$body-bg: #f7f7f7;
$body-color: #343741;
$font-family-sans-serif: 'MetaPro-Norm';
$link-color: #343741;

$light: $gray-200;

$navbar-light-color: #323b46;
$navbar-light-hover-color: $gray-900;

$border-radius-lg: 0.5rem;


$nav-link-padding-y: 1rem; 
$navbar-nav-link-padding-x: 1.5rem;

$btn-padding-x: 1em;
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;

$breadcrumb-bg:  transparent;
$breadcrumb-divider-color:          rgba(255,255,255,0.7);
$breadcrumb-active-color:           #fff;
$breadcrumb-divider:                quote("/");

$enable-rounded: false !default;