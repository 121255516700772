// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    #home-slider{
        .row{
            height: 400px;
        }
    }

    #adayogrenciSlider{
        .controls{
            position: absolute;
            right: -60px;
            top: -5px;
        }
    }

    #site-footer{
        ul{
            border-right:1px solid #c6c7ca;
        }
        .col-md-2{margin-top: -1.5rem;}
    }

    .tmp-akademi{
        .contents{
            padding:3rem;
        }
    }

    .tmp-iletisim{
        .bordered{border-right: 1px solid #d6d7d9}
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    #home-slider{
        h2{
            font-size:48px;
            margin-bottom: 0;
        }
        p{
            font-size:18px
        }
    }
    #home-news{
        .header{
            margin-bottom: 2rem;
        }
        article{
            margin-top: -4rem;
        }
        .carousel-item{ padding-top: 2rem; }
    }
    #haberlerSlider{
        .carousel-item{ padding-top: 2rem; }
    }

    #carouselFacultySliderControls {
        padding: 0 3rem;
    }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
    
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
    .navbar-brand img{
        max-width: 250px;
        margin-left: 1rem;
    }
    #home-slider{
        h2{
            font-size:32px;
            margin-bottom: 0;
        }
        p{
            font-size:15px
        }
    }



}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {

    #home-slider .content{
        padding:2rem;
    }
    
    #subMenu .list-inline{
        text-align: center;
        li{
            padding: 5px 0
        }
    }
    #tau-programs .program{
        padding-top: 1.25rem;
        margin-left: 15px;
        margin-right: 0;

        h4{
            padding: 0.75rem 1.25rem 0.75rem 1.75rem;
            margin-bottom: 15px;
        }
        
    }
    #adayogrenciSlider{
        margin-top: 1rem;
        .controls{transform: rotate(90deg); margin-left: 20px;}
    }

    #site-footer ul{
        margin-top: 1.5rem;
    }
    #site-footer{text-align: center;}
    #site-footer .material-icons{float:none; margin: 0px auto 10px auto; display: block;}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    #site-header .navbar{padding: 0;}
    #site-header .topmenu{
        padding-left: 0;
        position: relative;
        text-align: center;
        font-size:14px;
        
        &:after{display: none;}
        li{
            padding:7px;
            a{padding: 10px;}
        }
    }
    #site-header .nav-link{
        margin-top: 0;
        i{
            position: relative;
            top: 5px;
        }
    }
    #site-header .dropdown-menu{
        text-align: center;
        margin-bottom:1rem;
    }

    #home-announcements p{
        font-size:14px;
        small{font-size:12px}
    }

    #home-news article{
        margin-bottom: 1rem;
    }

    #academy-welcome article{
        margin-bottom: 1rem;
    }

    .rightbar{
        margin-top: 2rem;
    }

    #academy-welcome .overflow-image{
        margin-top: 2rem;
    }
    
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
    
}
